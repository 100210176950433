import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Headline from "../components/headline";
import Projects from "../components/projects";
// import Team from "../components/team";

const IndexPage = () => {  
  return (
  <Layout>
    <SEO title="Home" />
    <Headline />
    <Projects />
    {/* <Team /> */}
  </Layout>
)
  }

export default IndexPage
