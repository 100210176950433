import * as React from "react";
import { useRef, useEffect } from "react";
import Section from "./section";
import Project from "./project";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./projects.module.scss";
import VisionVideo from "../video/vision-speedup.mp4";
import VisionPoster from "../images/vision-poster.jpg";

const Projects = () => {
    const videoPlayer = useRef();

    useEffect(() => {
        videoPlayer.current.playbackRate = 0.5;
    }, []);

    const aggregator = (
        <StaticImage
            src="../images/nftaggregator-withgradient.jpg"
            alt="NFT Aggregator Image"
            className={styles.image}
            imgClassName={styles.image}
        />
    );

    const vision = (
        <video
            ref={videoPlayer}
            className={styles.video}
            poster={VisionPoster}
            autoPlay
            loop
            muted
        >
            <source src={VisionVideo} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    );

    const tba = (
        <StaticImage
            src="../images/tba-blurred.jpg"
            alt="NFT Aggregator Image"
            className={styles.image}
            imgClassName={styles.image}
        />
    );

    return (
        <>
            <div className={styles.sectionAdjustment} />
            <Section headline="Projects by Glow Labs" sideline="Every project created with love and care">
                <div className={styles.container}>
                    <Project
                        image={aggregator}
                        title="NFT multi-chain aggregator"
                        desc="Your cross-chain NFTs in one place"
                    />
                    <Project
                        image={vision}
                        title="Glow Vision"
                        desc="Virtual 3D NFT gallery"
                    />
                    <Project
                        image={tba}
                        title="TBA NFT Project"
                        desc="Coming Q1 2022"
                    />
                </div>
            </Section>
        </>
    )
};

export default Projects;
