import * as React from "react";
import * as styles from './section.module.scss';
import SectionHeadline from "./section-headline";

const Section = ({ headline, sideline, children }) => {
  return (
    <div className={styles.sectionContainer}>
        <SectionHeadline headline={headline} sideline={sideline} />
        {children}
    </div>
  )
}

export default Section
