import * as React from "react";
import * as styles from './footer.module.scss';
import Social from "./social";

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.internalContainer}>
        <Social altColor />
        <h6 className={styles.copyright}>Copyright © 2022 Glow Labs</h6>
      </div>
    </div>
  </footer>
);

export default Footer;
