import * as React from "react";
import * as styles from "./social.module.scss";
import { useState, useEffect } from 'react';
import Twitter from '../svgs/twitter.svg';
// import Discord from '../svgs/discord.svg';
import Mail from '../svgs/at.svg';

const Social = (props) => {
    const [colorClass, setColorClass] = useState(styles.primaryColor);

    useEffect(() => {
        if(!!props.altColor) {
            setColorClass(styles.altColor);
        } else {
            setColorClass(styles.primaryColor);
        }
    }, [props.altColor]);

    return(
        <div className={styles.socialContainer}>
            {/* <a href="https://twitter.com/CrystalRivals" className={`${styles.socialImage} ${colorClass}`}>
                <Twitter alt="Twitter" />
            </a> */}
            {/* <a to="/" className={`${styles.socialImage} ${colorClass}`}>
                <Discord alt="Discord" />
            </a> */}
            {/* <a href="mailto:contact@makeglow.com" className={`${styles.socialImage} ${colorClass}`}>
                <Mail alt="Mail" />
            </a> */}
        </div>
    )
};

export default Social;