import * as React from "react";
import * as styles from "./headline.module.scss";
import Prop from '../svgs/Prop.svg';
import { motion } from "framer-motion";

const Headline = () => (
    <div className={styles.headlineBackground1} >
        <div className={styles.headlineBackground2} >
            <div className={styles.headlineContainer} >
                <div className={styles.headlineTextContainer}>
                    <h1 className={`${styles.headlineText} ${styles.topHeadline}`}>We are</h1>
                    <h1 className={`${styles.headlineText} ${styles.accentColor}`}>Glow Labs</h1>
                    <p className={styles.headlineDescription}>A passionate blockchain dev team</p>
                </div>
                {typeof window !== `undefined` && window.innerWidth < 1280 ?
                    <div className={styles.propDisplay}>
                        <Prop />
                    </div>
                    :
                    <motion.div
                        className={styles.propDisplay}
                        drag
                        dragConstraints={{
                            top: -50,
                            left: -50,
                            right: 50,
                            bottom: 50,
                        }}
                    >
                        <Prop />
                    </motion.div>
                }
            </div>
        </div>
    </div>
);

export default Headline;