import * as styles from './header.module.scss';
import * as React from "react";
import { Link } from "gatsby";
import Logo from '../svgs/logo-shapes.svg';
import Social from "./social";

const Header = () => (
  <header className={styles.header}>
    <div className={styles.container}>
      <div className={styles.internalContainer}>
        <div className={styles.siteTitle}>
          <Link to="/">
            <Logo
              className={styles.logoStyle}
              alt="Glow Logo"
            />
          </Link>
        </div>
        <div className={styles.extraBtnMargin}>
          <Social />
        </div>
      </div>
    </div>
  </header>
);

export default Header;
