import * as React from "react";
import PropTypes from "prop-types";
import * as styles from "./section-headline.module.scss";

const SectionHeadline = ({ headline, sideline }) => (
    <div className={styles.headlineContainer} data-aos="fade-up">
        <h1 className={styles.headline}>{headline}</h1>
        <h2 className={styles.sideline}>{sideline}</h2>
    </div>
);

SectionHeadline.propTypes = {
    headline: PropTypes.string,
    sideline: PropTypes.string
}
  
SectionHeadline.defaultProps = {
    headline: ``,
    sideline: ``
}

export default SectionHeadline;