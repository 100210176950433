// extracted by mini-css-extract-plugin
export var headlineBackground1 = "headline-module--headlineBackground1--3LlVa";
export var headlineBackground2 = "headline-module--headlineBackground2--2AXlx";
export var headlineContainer = "headline-module--headlineContainer--zPVAe";
export var headlineTextContainer = "headline-module--headlineTextContainer--oPdQ8";
export var headlineText = "headline-module--headlineText--1-MId";
export var accentColor = "headline-module--accentColor--3X9BN";
export var headlineDescription = "headline-module--headlineDescription--3uMP7";
export var headlineButtonGroup = "headline-module--headlineButtonGroup--3L5GK";
export var extraBtnMargin = "headline-module--extraBtnMargin--33nG4";
export var propDisplay = "headline-module--propDisplay--Q8g7e";
export var topHeadline = "headline-module--topHeadline--1wfFn";
export var propImage = "headline-module--propImage--bR0PK";