import * as React from "react";
import * as styles from "./project.module.scss";

const Project = ({ image, title, desc }) => (
    <div className={styles.container} data-aos="fade-up">
        <div className={styles.imageContainer}>
            {image}
        </div>
        <div className={styles.textContainer}>
            <div className={styles.titleContainer}>
                {title}
            </div>
            <div className={styles.descContainer}>
                {desc}
            </div>
        </div>
    </div>
);

export default Project;
