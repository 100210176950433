import * as React from "react";
import PropTypes from "prop-types";
import "./global.scss";

import Header from "./header";
import * as styles from './layout.module.scss';
import Footer from "./footer";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Layout = ({ children }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <div className={styles.layoutContainer}>
      <Header />
      <div className={styles.sections}>
        <main className={styles.mainContainer}>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
